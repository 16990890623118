body{
    background: #f0f4f5;
    color: #212b32;
    .button-start, .button-link, .button-primary {
        background-color: #007f3b;
        border: 2px solid transparent;
        border-radius: 4px;
        box-shadow: 0 4px 0 #00401e;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        margin-top: 0;
        padding: 8px 10px 7px;
        text-align: center;
        vertical-align: top;
        width: auto;
        font-size: 19px;
        line-height: 1;
        margin-right: 10px;
        &:hover{
            background-color: #00662f;
        }
        &:focus{
            background: #ffeb3b;
            box-shadow: 0 4px 0 #212b32;
            color: #212b32;
            outline: 4px solid transparent;
        }
    }
    .button-secondary, .sv_main .sv_container .sv_body .sv_nav .sv_next_btn, .sv_main .sv_container .sv_body .sv_nav .sv_prev_btn{
        background-color: #4c6272;
        border: 2px solid transparent;
        border-radius: 4px;
        box-shadow: 0 4px 0 #263139;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        margin-top: 0;
        padding: 8px 10px 7px;
        text-align: center;
        vertical-align: top;
        width: auto;
        font-size: 19px;
        line-height: 1;
        &:hover{
            background-color: #384853;
            color: #fff;
        }
        &:focus{
            background: #ffeb3b;
            box-shadow: 0 4px 0 #212b32;
            color: #212b32;
            outline: 4px solid transparent;
        }
    }
    .button-secondary-reverse{
        background-color: #fff;
        box-shadow: 0 4px 0 #212b32;
        color: #212b32;
        &:hover{
            background-color: #f2f2f2;
            color: #212b32;
        }
    }
    .button-warning{
        background-color: #d5281b;
        border: 2px solid transparent;
        border-radius: 4px;
        box-shadow: 0 4px 0 #902413;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-weight: 600;
        margin-top: 0;
        padding: 8px 10px 7px;
        text-align: center;
        vertical-align: top;
        width: auto;
        font-size: 19px;
        line-height: 1;
    }
    header.header {
        background:#f08030;
        a{
            color: #fff;
            &:hover{
                text-decoration: none;
                color: #fff;
            }
        }
        .menu{
            top: 0px!important;
        }
        .header-container{
            padding-top: 15px;
            .header-link-home {
                padding: 0!important;
                margin-bottom: 15px;
                .header-logotype {
                    .logo-icon{
                        display: block;
                        margin-top: 0;
                        height: 30px;
                        position: absolute;
                        z-index: 9;
                    }
                    .logo-name{
                        height: 20px;
                        margin-top: 0px;
                        filter: brightness(100);
                    }
                }
                &:focus{
                    .header-logotype {
                        .logo-name{
                            filter: brightness(0);
                        }
                    }
                }
            }
        }
        .header-navigation-container-background{
        border-top-color: transparent;
        background: transparent;
        border-top: 1px solid rgba(255,255,255,0.2);
        }
        .header-navigation-container {
            padding: 0;
            .rightNav{
                li.active{
                    box-shadow: none;
                }
            }
            ul{
                height: 35px;
                li{
                    border: none;
                    height: 50px;     
                    .navigation-item {
                        color: #fff;
                        line-height: 2.9;
                        &:focus{
                            background: #ffeb3b;
                            border-bottom: 4px solid #212b32;
                            color: #212b32!important;
                            box-shadow: none;
                        }
                    } 
                }
                li.smDiv {
                    height: 32px;
                    .chat-container {
                        margin-top: -16px;
                        .display-panel{
                            width: 290px;
                            padding: 15px 10px;
                        }
                        .btn{
                            padding: 11px 15px;
                            .chat-text{
                                color: #fff;
                            }
                            &:focus{
                                .chat-text{
                                    color: #212b32;
                                }
                            }
                        }
                    }
                }
                li.active {
                    box-shadow: inset 0 -4px 0 #d8dde0;
                    border: none;
                    &:hover{
                        .navigation-item{
                            text-decoration: underline;
                        }
                    }
                }
            }
            ul.rightNav{
                li{
                    height: 36px;
                }
            }
        }
    }
    main{
        background: #f0f4f5;
        .plan-new-wrapper{
            background-color: #fff;
        }
        .messages-page{
            background-color: #fff;
            .messages-container-header{
                padding: 10px 10px 13px 10px!important;
            }
            .messages-container{
                .message-list-left{
                    li.active{
                        background-color: #ffeb3b;
                    }
                    .moj-side-navigation__item {
                        .avatar-circle{
                            background-color:#f08030!important;
                        }
                    }
                }
                .message-list-right{
                    .hin-message{
                        color: #4c6272;
                        font-size: 19px;
                    }
                }
            }
        }
        .radio-wrapper {
            input[type=radio] {
                ~ label {
                    &::before {
                        border-color: #4c6272;
                    }
                    &::after{
                        border-color: #212b32;
                    }
                }
                &:focus{
                    ~ label {
                        &::before {
                            border-color: #212b32;
                        }
                    }
                }
            }
        }
    }
    .footer{
        background-color: #d8dde0;
        border-top: 4px solid#f08030;
        color: #4c6272;
        padding: 32px 0;
        .footer-copyright{
            margin: 0;
        }
        .column-three-quarter{
            margin: 0;
            .nhsuk-footer__list{
                margin: 0;
                a{
                    color: #4c6272;
                    &:hover{
                        color: #212b32;
                        text-decoration: none;
                    }
                }
            }
        }
        .text-right{
            display: block;
            float: right;
            margin-top: 5px;
        }
    }
}
.nhsuk-header__search{
    font-family: "Frutiger";
    color: #212b32;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    box-sizing: inherit;
    position: relative;
    text-align: right;
    margin-left: 8px;
    user-select: auto;
    .nhsuk-header__search-wrap{
        display: block;
        line-height: 0;
        user-select: auto;
        .nhsuk-header__search-form{
            height: 100% ;
            overflow: visible;
            user-select: auto;
        } 
    }
}
.autocomplete-container{
    font-family: Frutiger W01,Arial,Sans-serif;
    color: #212b32;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    text-align: right;
    line-height: 0;
    box-sizing: inherit;
    display: inline-block;
    z-index: 1;
    user-select: auto;
}
.nhsuk-search__submit{
    -webkit-font-smoothing: antialiased;
    box-sizing: inherit;
    font-family: inherit;
    border: 1px solid #f0f4f5;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
    float: right;
    font-size: inherit;
    line-height: inherit;
    outline: none;
    padding: 0;
    background-color: #f0f4f5;
    display: block;
    height: 38px;
    width: 44px;
    user-select: auto;
    .nhsuk-icon__search{
        fill: $link-colour;
    }
    &:hover{
        background-color: #003d78;
        border: 1px solid #fff;
        cursor: pointer;
        fill: #fff;
        .nhsuk-icon__search{
            fill: #fff;
        }
    }
}
.nhsuk-icon .nhsuk-icon__search{
    fill:#f08030;
    height: 27px ;
    width: 27px ;
    user-select: auto;
}
.autocomplete__input{
    -webkit-font-smoothing: antialiased;
    box-sizing: inherit;
    -webkit-appearance: listbox;
    border: 1px solid #fff !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0 ;
    border-right: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 0 ;
    font-size: 16px;
    padding: 0 12px;
    height: 38px !important;
    width: 200px !important;
    user-select: auto;
    &:focus{
        border: 2px solid #212b32;
        box-shadow: 0 0 0 4px #ffeb3b;
        outline: 4px solid transparent;
        outline-offset: 4px;
        padding: 0 11px;
    }
}
@media (min-width: 40.0625em){
    .nhsuk-search__submit{
        &:focus {
        background-color: #ffeb3b;
        border: 0;
        box-shadow: 0 4px 0 0 #212b32;
        color: #212b32;
        outline: 4px solid transparent;
        outline-offset: 4px;
        height: 40px;
        width: 46px;
        box-shadow: 0 -2px #ffeb3b, 0 4px #212b32;
            .nhsuk-icon__search{
                fill: #212b32;
            }
        }
    }
}
.link_table, .assessment_table, .profileWrapper, .tabs-panel{
    background-color: #fff;
    border: 1px solid #d8dde0;
    padding: 32px!important;
}
.change-password-align{
    display: inline-block;
    margin-top: 17px;
}
.header{
    background: transparent;
}
table{
    tr {
        th{
            // border-bottom: 2px solid #d8dde0;
        }
        td{
            // border-bottom: 1px solid #d8dde0;
            padding-right: 24px!important;
            padding-top: 16x!important;
            padding-bottom: 16px!important;  
        }
    }
}
#localViewJob, .expression-of-interest-table{
    table{
        tr{
            th{
                border-bottom-width: 1px;
                padding-top: 8px!important;
                padding-bottom: 8px!important;
            }
            td{
                padding-top: 8px!important;
                padding-bottom: 8px!important;
            }
        }
    }
}
.selectOptions{
    button{
        background-color: #fff;
        border: 1px solid #d8dde0!important;
    }
    .decorated{
        background-color: #ffeb3b!important;
    }
}
.sv_main .sv_container .sv_header, .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn, .sv_main .sv_container .sv_body .sv_nav{
    background: #f0f4f5!important;
}
//changes for NLA-345
.search-btn{
    margin-top: -10px !important;
}
input{
    border: 2px solid $grey-1 !important;
    &:focus{
        border: 2px solid $black !important;
        outline: 4px solid #ffeb3b;
    }
}
textarea{
    border: 2px solid $grey-1 !important;
    padding: 4px !important;
    &:focus{
        border: 2px solid $black !important;
        outline: 4px solid #ffeb3b !important;
    }
}
select{
    border: 2px solid $grey-1 !important;
    height: 40px !important;
    &:focus{
        border: 2px solid #212b32 !important;
        box-shadow: inset 0 0 0 2px;
        outline: 4px solid #ffeb3b;
        outline-offset: 0;
    }
}
.radio-wrapper input[type=radio] {
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.radio-wrapper input[type=radio] ~ label::before {
    border: 2px solid $grey-1;
    background: #fff;
}
.radio-wrapper input[type=radio]:focus + label::before {
    background: #fff;
    border: 4px solid #212b32;
    box-shadow: 0 0 0 4px #ffeb3b;
}
.checkbox-wrapper input[type=checkbox] {
    top: 0px;
    left: 0px;
    width: 40px;
    height: 40px;
}
.checkbox-wrapper input[type=checkbox] ~ label::before {
    content: "";
    border: 2px solid $grey-1;
    background: #fff;
}
.checkbox-wrapper input[type=checkbox]:focus + label::before {
    box-shadow: 0 0 0 4px #ffeb3b;
    border: 4px solid $black;
}
.status-tag, .status.not-started{
    background-color: #dbe0e3!important;
    border:1px solid #354550!important;
    color: #354550!important;
    font-size: 16px!important;
    font-family: "Frutiger_bold"!important;
    padding: 5px 8px 3px!important;
    line-height: 1!important;
}
.status-tag.green, .status.in-progress{
    background-color: #fff!important;
    border: 1px solid #212b32!important;
    color: #212b32!important;
    font-size: 16px!important;
    font-family: "Frutiger_bold"!important;
    padding: 5px 8px 3px!important;
    line-height: 1!important;
}
.status-tag.blue, .status{
    background-color: #cce5d8!important;
    border: 1px solid #004c23!important;
    color: #004c23!important;
    font-size: 16px!important;
    font-family: "Frutiger_bold"!important;
    padding: 5px 8px 3px!important;
    line-height: 1!important;
}
.button-secondary[disabled]:hover {
    background-color: #384853;
}
.button-warning:focus{
    background: #ffeb3b;
    box-shadow: 0 4px 0 #212b32;
    color: #212b32;
    outline: 4px solid transparent;
}
svg{
    cursor: pointer;
    &:focus{
        circle{
            fill: #000 !important;
        }
    }
}
.summary-list-actions{
    max-width: 130px;
    width: 130px;
}
//changes under NLA-492
.nhsuk-warning-callout{
    font-family: 'Frutiger';
    background-color: #fff9c4;
    color: #212b32;
    border: 1px solid #ffeb3b;
    padding-top: 0 !important;
    margin-bottom: 48px;
    margin-top: 48px;
    padding: 32px;
    user-select: auto;
    margin-top: -1px;
    margin: 10px 20px 30px 0px;
    align-items: center;
    .nhsuk-warning-callout__label{
        font-weight: 600;
        background-color: #ffeb3b;
        color: #212b32;
        display: inline-block;
        margin: 0 0 8px -33px;
        padding: 8px 32px;
        position: relative;
        top: -16px;
        font-size: 24px;
        line-height: 1.33333;
        margin-top: 0;
        user-select: auto;
        font-family: 'Frutiger_bold';
        .nhsuk-u-visually-hidden{
            border: 0;
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            height: 1px;
            margin: 0;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }
    }
    .errorMsg{
        margin-top: 0px;
        margin-bottom: 0px;
        font-family: 'Frutiger';
    }
}


//changes under NLA-494
body header.header .header-navigation-container {
    padding: 1px;
}
body header.header .header-navigation-container ul {
    height: 34px;
}
//changes under NLA-640
.hin-message{
    color: #4C6272 !important;
    font-size: 19px !important;
}
.sv_q_description {
    color: #4C6272 !important;
    font-size: 19px;
}
//changes under NLA-646
.nhsuk-icon__search {
    right: 8px !important;
}
.footer .text-right {
    margin-right: 16px !important;
}
.smDiv{
    padding: 0px !important;
}
.dark-mode-button:active {
    top: 19px !important;
}
//changes under NLA-653
.heading-large{
    font-size: 32px !important;
}
.heading-medium{
    font-size: 22px !important;
}
.main-heading{
    font-size: 24px !important;
    color: #4C6272 !important;
    font-family: "Frutiger",Arial,Sans-serif !important;
}
.form-label-b19 span {
    color: #4C6272 !important;
}
.radio-wrapper.radiobg input[type=radio] ~ label::before {

    border: 2px solid $grey-1;

    background: none !important;

}
//Changes under NLA-493
.nav-container{
    flex: 0 0 auto !important;
    width: 220px !important;
    user-select: auto !important;
    nav.side-navigation{
        line-height: 1.5;
        box-sizing: inherit;
        font-size: 16px;
        ul.side-navigation__list{
            list-style-type: none;
            margin-top: 0;
            padding-left: 0;
            font-weight: 400;
            margin-bottom: 0;
            a{
                width: 100% !important;
                font-family: Frutiger W01,Arial,Sans-serif !important;
                &:focus{
                    background-color: #ffeb3b !important;
                    box-shadow: 0 -2px #ffeb3b, 0 4px #212b32 !important;
                    color: #212b32 !important;
                    outline: 4px solid transparent !important;
                    text-decoration: none !important;
                }
                &:hover{
                    color: #7C2855 !important;
                    text-decoration: underline !important;
                }
                &:focus:hover{
                    text-decoration: none !important;
                }
            }
        }  
        li.side-navigation__item a{
            color:#f08030 !important;
        }
        li.side-navigation__item{
            margin-bottom: 8px !important;
        }
        li.side-navigation__item.side-navigation__item-active{
            border-left: 4px solid#f08030 !important;
            a{
                font-weight: 600 !important;
            }
        }
    }
}
li.side-navigation__item a{
    border-left: 0px solid transparent !important;
}
.caption-div{
    svg {
        position: inherit!important;
    }
}
//changes under NLA-743
.sv_next_btn{
    margin-right: 15px !important;
    &:active{
        background: #ffeb3b !important;
        box-shadow: 0 4px 0 #212b32 !important;
        color: #212b32 !important;
        outline: 4px solid transparent !important;
    }
}
.form-group-error input, .form-group-error textarea {
    border: 2px solid #d5281b !important;
}
