.icon-menu {
  display: block;
  span {
    width: 25px;
    height: 3px;
    background-color: #212b32;
    margin: 4px 0;
    display: block;
    opacity: 0.8;
  }
}
